import request from '@/utils/request'


// 查询ai使用记录列表
export function listAiRecord(query) {
  return request({
    url: '/user/user-ai-records/list',
    method: 'get',
    params: query
  })
}

// 查询ai使用记录分页
export function pageAiRecord(query) {
  return request({
    url: '/user/user-ai-records/page',
    method: 'get',
    params: query
  })
}



export function getUserAi(query) {
  return request({
    url: '/user/user-ai-records/getUserAi',
    method: 'get',
    params: query
  })
}

// 查询ai使用记录详细
export function getAiRecord(data) {
  return request({
    url: '/user/user-ai-records/detail',
    method: 'get',
    params: data
  })
}

// 新增ai使用记录
export function addAiRecord(data) {
  return request({
    url: '/user/user-ai-records/add',
    method: 'post',
    data: data
  })
}

// 修改ai使用记录
export function updateAiRecord(data) {
  return request({
    url: '/user/user-ai-records/edit',
    method: 'post',
    data: data
  })
}

// 删除ai使用记录
export function delAiRecord(data) {
  return request({
    url: '/user/user-ai-records/delete',
    method: 'post',
    data: data
  })
}
